import Api from './ApiService'

export default {
    get(keyword, pageNumber, sort) {
        return Api().get(`payment?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}`);
    },
    getOne(id) {
        return Api().get(`payment/${id}`);
    },
    update(data) {
        return Api().put(`payment/${data.id}`, data);
    }
}