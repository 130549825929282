import Api from './ApiService'

export default {
    get(keyword, pageNumber, sort) {
        return Api().post(`order?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}`);
    },
    getOne(id) {
        return Api().get(`order/${id}`);
    },
    getTotalPendentes() {
        return Api().get(`order/total-pendentes`);
    },
    update(data) {
        return Api().put(`order/${data.id}`, data);
    }
}