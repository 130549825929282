import Api from './ApiService'

export default {
    get(keyword, pageNumber, sort) {
        return Api().post(`users?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}`);
    },
    getOne(id) {
        return Api().get(`users/${id}`);
    },
    updateUser(credentials){
        return Api().put(`users/update/user/${credentials.id}`, credentials);
    },
}