import OrderService from '../../services/OrderService'

const state = () => ({
    loadingOrder: false,
    order: null,
    orders: [],
    total: 0,
    pages: 0,
    page: 1,
    totalOrdersPendentes: null
})
  
const mutations = {
    SET_ORDER: (state, payload) => {
        state.order =  payload
    },
    SET_ORDERS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.orders = payload.orders
        }else{
            state.orders = [...state.orders, ...payload.orders]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_LOADING_ORDER: (state, payload) => {
        state.loadingOrder = payload
    },
    SET_TOTAL_ORDERS_PENDENTES: (state, payload) => {
        state.totalOrdersPendentes = payload.pendingLogsCount
    },
}

const actions = {
    async fetchOrders({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            commit('SET_LOADING_ORDER', true)
            const response = await OrderService.get(keyword, pageNumber, sortBy);
            commit('SET_ORDERS', response.data)
            commit('SET_LOADING_ORDER', false)
        } catch (error) {
            commit('SET_LOADING_ORDER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchOrder({commit}, id){
        try{
            commit('SET_LOADING_ORDER', true)
            const response = await OrderService.getOne(id);
            response.data.deliveryAddress = JSON.parse(response.data.deliveryAddress);
            response.data.shippingMethod = JSON.parse(response.data.shippingMethod);
            commit('SET_ORDER', response.data)
            commit('SET_SHIPPING', response.data.OrderShippings[0])
            commit('SET_LOADING_ORDER', false)
        } catch (error) {
            commit('SET_LOADING_ORDER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchTotalPendentesPendentes({commit}){
        try{
            commit('SET_LOADING_ORDER', true)
            const response = await OrderService.getTotalPendentes();
            commit('SET_TOTAL_ORDERS_PENDENTES', response.data)
            commit('SET_LOADING_ORDER', false)
        } catch (error) {
            commit('SET_LOADING_ORDER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeOrder({commit}, data){
        try{
            commit('SET_LOADING_ORDER', true)
            const objOrder = Object.assign({}, data); 
            objOrder.deliveryAddress = JSON.stringify(objOrder.deliveryAddress); 
            objOrder.shippingMethod = JSON.stringify(objOrder.shippingMethod); 
            const response = await OrderService.update(objOrder);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_ORDER', false)
        } catch (error) {
            commit('SET_LOADING_ORDER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getOrder: state => state.order,
    getOrders: state => state.orders,
    getLoadingOrder: state => state.loadingOrder,
    getTotalOrdersPendentes: state => state.totalOrdersPendentes
}

export default {
    state,
    getters,
    mutations,
    actions
};