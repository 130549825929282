import ProductService from '../../services/ProductService'
import router from '../../router'

const state = () => ({
    loadingProduct: false,
    product: null,
    products: [],
    total: 0,
    pages: 0,
    page: 1,
    database: 'laef2023',
    showVerificarTamanho: false,
    newProduct: JSON.parse(localStorage.getItem('newProduct')) || {
        id: null,
        name: "",
        description: "",
        price: 0,
        discount: null,
        quantity: 0,
        brand: "",
        model: null,
        sizeGuide: null,
        weight: null,
        height: null,
        width: null,
        length: null,
        availability: "em estoque",
        status: "ativo",
        RelationId: null,
        avg_rating: "0",
        num_reviews: 0,
        Reviews: [],
        Images: [],
        Categories: [],
        Attributes: [],
        Tags: [],
        ProductCharVariations: [],
        Relations: []
    },
})
  
const mutations = {
    SET_PRODUCT: (state, product) => {
        state.product = product
    },
    SET_PRODUCTS: (state, payload) => {
        state.products = payload.products
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total = payload.total
    },
    SET_MORE_PRODUCTS(state, payload) {
        state.products = [...state.products, ...payload.products]
        state.page =  payload.pageNumber
        state.pages =  payload.pages
    },
    SET_SEARCH_PRODUCTS: (state, payload) => {
        state.products = payload.products
    },
    SET_LOADING_PRODUCT: (state, payload) => {
        state.loadingProduct = payload
    },
    UPDATE_VAR_PRODUCT: (state, payload) => {
        state.product.ProductCharVariations = payload
    },
    SHOW_MODAL_VERIFICAR_TAMANHO: (state, boolean) => {
        state.showVerificarTamanho = boolean
    },
}

const actions = {
    async fetchProduct({commit}, id){
        try {
            commit('SET_LOADING_PRODUCT', true);
            const response = await ProductService.getOne(id);
            commit('SET_PRODUCT', response.data)
            commit('SET_LOADING_PRODUCT', false);
        } catch (error) {
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchSearchProducts({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            const response = await ProductService.get(keyword, pageNumber, sortBy);
            commit('SET_SEARCH_PRODUCTS', response.data)
        } catch (error) {
            commit('SET_LOADING_PRODUCT', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchProducts({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            commit('SET_LOADING_PRODUCT', true)
            const response = await ProductService.get(keyword, pageNumber, sortBy);
            commit('SET_PRODUCTS', response.data)
            commit('SET_LOADING_PRODUCT', false)
        } catch (error) {
            commit('SET_LOADING_PRODUCT', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchMoreProducts({commit}, { keyword = '', pageNumber, sortBy = 'mais recentes'} ){
        try{
            commit('SET_LOADING_PRODUCT', true)
            const response = await ProductService.get(keyword, pageNumber, sortBy);
            commit('SET_MORE_PRODUCTS', response.data)
            commit('SET_LOADING_PRODUCT', false)
        } catch (error) {
            commit('SET_LOADING_PRODUCT', false);
            commit('LOG_USER_OUT'); //Deu errado desloga
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createProduct({ state, commit , dispatch, rootState}) {
        try{
            const imageFiles = [...state.newProduct.Images];
            var product = Object.assign({}, state.newProduct); // Cria uma cópia do objeto state.newProduct
            delete product.Images;

            const response = await ProductService.create({product});
            const newProduct = response.data.newProduct

            if(response && newProduct) {
                await dispatch('uploadImages', { idProduct: newProduct.id, imageFiles });
                await dispatch('updateCharacteristic', { idProduct: newProduct.id, Characteristic: rootState.characteristic_variations.selectedCharacteristic });

                router.push({path: `/form_product/${newProduct.id}`});
            }
        } catch (error) {
            commit('SET_LOADING_PRODUCT', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updateProduct({ state, commit, rootState, dispatch }) {
        try {
            commit('SET_LOADING_PRODUCT', true);
            if (await areObjectsDifferent(rootState.characteristic_variations.selectedCharacteristic, rootState.characteristic_variations.characteristicAtual)) {
                await dispatch('updateCharacteristic', { idProduct: state.product.id, Characteristic: rootState.characteristic_variations.selectedCharacteristic });
            }
            const imageFiles = [...state.product.Images];

            await dispatch('uploadImages', { idProduct: state.product.id, imageFiles });

            var product = Object.assign({}, state.product); // Cria uma cópia do objeto state.newProduct
            delete product.Images;
            const response = await ProductService.update({product});
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_PRODUCT', false);
        } catch (error) {
            commit('SET_LOADING_PRODUCT', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async uploadImages({ commit }, { idProduct, imageFiles }) {
        try {
            const formData = new FormData();
            formData.append('idProduct', idProduct);
            
            var newImages = [];
            var oldImages = [];

            for (let i = 0; i < imageFiles.length; i++) {
                if (typeof imageFiles[i] === 'string') {
                    const file = await convertDataUrlToFile(imageFiles[i], i);
                    formData.append('images', file);
                    newImages.push({order: i})
                } else if (typeof imageFiles[i] === 'object' && imageFiles[i].id) {
                    oldImages.push({id: imageFiles[i].id , filename: imageFiles[i].filename, order: i})
                }
            }
            
            formData.append('newImages', JSON.stringify(newImages));
            formData.append('oldImages', JSON.stringify(oldImages));
            
            await ProductService.uploadImages(formData);
        } catch (error) {
          commit('SET_ALERT', {
            heading: 'error',
            message: error.response.data.message
          });
          throw error;
        }
    },
    async duplicateProduct({ state, commit, rootState}, ) {
        try{
            commit('SET_LOADING_PRODUCT', true);
            var newProductDuplicate = Object.assign({}, state.product);
            newProductDuplicate.name = state.product.name + ' [Copia]' 
            newProductDuplicate.Images = [];
            state.newProduct = newProductDuplicate
            
            const characteristic = rootState.product.product.ProductCharVariations.find((variation) => variation.status === 'ativo');
            let objCharacteristc
            if(characteristic && characteristic.Characteristic) {
                objCharacteristc = characteristic.Characteristic;
            }else{
                objCharacteristc = null;
            }
            commit('SET_CHARACTERISTIC_ATUAL', objCharacteristc)
            commit('SET_CHARACTERISTIC_SELECTED', objCharacteristc)

            router.push({path: `/form_product/`});
            commit('SET_ALERT', {
                heading: 'success',
                message: 'Produto Duplicado'
            });
            commit('SET_SHOW_MODAL_CONFIRMATION', false);
            commit('SET_LOADING_PRODUCT', false);
        } catch (error) {
            commit('SET_LOADING_PRODUCT', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async deleteProduct({ state, commit}, idProduct) {
        try{
            commit('SET_LOADING_PRODUCT', true);
            if(state.product.id == idProduct){
                const response = await ProductService.delete(idProduct);
                router.push({path: `/products`});
                commit('SET_ALERT', {
                    heading: 'success',
                    message: response.data.message
                });
            }
            commit('SET_SHOW_MODAL_CONFIRMATION', false);
            commit('SET_LOADING_PRODUCT', false);
        } catch (error) {
            commit('SET_LOADING_PRODUCT', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    }
}

export async function areObjectsDifferent(obj1, obj2) {
    var obj1Null = false;
    var obj2Null = false;
    
    if (obj1 === null || obj1 === undefined || (typeof obj1 === 'object' && Object.keys(obj1).length === 0)) {
        obj1Null = true;
    }
    if (obj2 === null || obj2 === undefined || (typeof obj2 === 'object' && Object.keys(obj2).length === 0)) {
        obj2Null = true;
    }
    if (obj1Null === obj2Null) {
        if((obj1Null == false && obj2Null == false) && JSON.stringify(obj1) !== JSON.stringify(obj2)){
            return true; // Objetos são diferentes
        }
        return false;
    }
    return true; // Objetos são diferentes
}

export async function convertDataUrlToFile(dataUrl, index) {
    const parts = dataUrl.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const data = atob(parts[1]);
    const arrayBuffer = new ArrayBuffer(data.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < data.length; i++) {
      uint8Array[i] = data.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: contentType });
    const fileName = `image_${index}.png`;
    const file = new File([blob], fileName, { type: contentType });
    return file;
}

const getters = {
    getProduct: state => state.product,
    getProducts: state => state.products,
    getNewProduct: state => state.newProduct,
    getLoadingProduct: state => state.loadingProduct,
    getShowModalVerificarTamanho: state => state.showVerificarTamanho,
}

export default {
    state,
    getters,
    mutations,
    actions
};